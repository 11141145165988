import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCuttlefish, faHtml5, faJsSquare, faLinux, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
    const [letterClass , setLetterClass] = useState('text-animate')
    
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
    <>
        <div className="container about-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters 
                        letterClass={letterClass}
                        strArray={['A','b','o','u','t',' ','m','e','.']}
                        idx={15}
                    />
                </h1>
                <p>I'm a junior developer and student 
                    at the University of Athens 
                    in Digital Industry Technologies.</p>
                <p>I like Python, C++, Linux, web development, 
                    learning, and in general building projects.</p>
            </div>

            <div className="prog-icon">
                <div className="faces">
                    <div className="face1">
                        <FontAwesomeIcon icon={faPython} color="#306998" />
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faCuttlefish} color="#004482" />
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className="face4">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faLinux} color="black" />
                    </div>
                    <div className="face6">
                    <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                </div>
            </div>
        </div>
        <Loader type="ball-scale-ripple" />
    </>
    )
}

export default About