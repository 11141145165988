// eslint-disable-next-line
import { Link, NavLink } from 'react-router-dom'
import './/index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome , faUser , faBars , faClose } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin , faGithub , faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'

const Sidebar =  () => {

    const [showNav, setShowNav] = useState(false);

    return (
    <div className='nav-bar'>
        <h1 className='title-name'>e=&#123;plk&#125;</h1>
        <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink onClick={() => setShowNav(false)} exact="true" activeClassName="active" to="/">
                <FontAwesomeIcon icon={faHome} color="#ffd700" />
            </NavLink>
            <NavLink onClick={() => setShowNav(false)} exact="true" activeClassName="active" className="about-link" to="/about">
                <FontAwesomeIcon icon={faUser} color="#ffd700" />
            </NavLink>
            <NavLink onClick={() => setShowNav(false)} exact="true" activeClassName="active" className="contact-link" to="/contact">
                <FontAwesomeIcon icon={faEnvelope} color="#ffd700 " />
            </NavLink>
            <FontAwesomeIcon onClick={() => setShowNav(false)} icon={faClose} color="#ffd700" size="3x" className="close-icon" />
        </nav>
        <ul className='social-icon'>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/enrik-paloka/">
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://github.com/en-rik/">
                    <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/enrik_plk/">
                    <FontAwesomeIcon icon={faTwitter} color="#4d4d4e" />
                </a>
            </li>
        </ul>
        <FontAwesomeIcon onClick={() => setShowNav(true)} icon={faBars} color="#ffd700" size="3x" className="hamburger-icon" />
    </div>
)
}

export default Sidebar