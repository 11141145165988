import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from "../AnimatedLetters"
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin , faGithub , faTwitter } from '@fortawesome/free-brands-svg-icons'
import 'react-toastify/dist/ReactToastify.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Contact = () => {
    const [letterClass , setLetterClass] = useState('text-animate')
    const refForm = useRef()
    
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e) => {
      e.preventDefault()

      emailjs
        .sendForm(
          'service_q71r20u',
          'template_ey3uz3q',
          refForm.current,
          'WVDbG86MDEXiNZbKV'
        )

        .then(
          () => {
            toast.success('Message successfully sent!', {
              theme: "dark" 
            })
          },
          () => {
            toast.error('Failed to send the message, please try again', {
              theme: "dark" 
            })
          }
        )
    }
    
    return (
        <>
          <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                <AnimatedLetters
                  letterClass={letterClass}
                  strArray={['C','o','n','t','a','c','t',' ','m','e','.']}
                  idx={15}
                />
                </h1>
                <p>Feel free to contact me about programming projects or general things.</p>
                <div className='contact-form'>
                  <form ref={refForm} onSubmit={sendEmail}>
                    <ul>
                      <li className='half'>
                        <input type="text" name='from_name' placeholder='Name' required />
                      </li>
                      <li className='half'>
                        <input type="email" name='email' placeholder='Email' required />
                      </li>
                      <li>
                        <input placeholder='Subject' type='text' name='subject' required />
                      </li>
                      <li>
                        <textarea placeholder='Messege' name='message' required ></textarea>
                      </li>
                      <li>
                        <input type='submit' className='flat-button' value='SEND'/>
                      </li>
                      <li className='social-icon'>
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/enrik-paloka/">
                          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://github.com/en-rik/">
                          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/enrik_plk/">
                          <FontAwesomeIcon icon={faTwitter} color="#4d4d4e" />
                        </a>
                      </li>
                    </ul>
                  </form>
                </div>
            </div>
            <div className='info-map'>
              Enrik Paloka,
              <br />
              Greece,
              <br />
              Athens 155 58 .
            </div>
            <div className='map-wrap'>
              <MapContainer center={[37.983926, 23.728057]} zoom={10} attributionControl={false}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[37.983926, 23.728057]}>
                  <Popup>Enrik is here :)</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
          <Loader type='ball-scale-ripple' />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
    )
}

export default Contact