import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';

const Home = () => {
    const [letterClass , setLetterClass] = useState('text-animate')
    const jobArray = ['D' , 'e' , 'v']

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])
    
    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>Hi,  I'm<br /> 
                <span className={letterClass}>E</span>nrik&nbsp;
                <span className={`${letterClass} _12`}>P</span>a
                <span className={`${letterClass} _14`}>l</span>o
                <span className={`${letterClass} _16`}>k</span>a
                <br />
                and i'm a
                Junior <AnimatedLetters letterClass={letterClass}
                strArray={jobArray}
                idx={18} />eloper&nbsp;
                <span className={`${letterClass} _23`}>.</span>
                </h1>
                <h2>Student / Developer / Python Junior</h2>
                <Link to="/contact" className='flat-button'>CONTACT ME</Link>
            </div>
        </div>
        <Loader type='ball-scale-ripple'/>
        </>
    );
}

export default Home