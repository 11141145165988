import './App.scss';
import { Routes , Route } from 'react-router-dom';
import Layout from './/components//Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD9F2zx0tvVaWRyu09JcYrLsdoSAfJqTpI",
  authDomain: "portfolio-eplk.firebaseapp.com",
  databaseURL: "https://portfolio-eplk-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-eplk",
  storageBucket: "portfolio-eplk.appspot.com",
  messagingSenderId: "240558382076",
  appId: "1:240558382076:web:711450faeb0686efac3079"
};
// eslint-disable-next-line
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
    
    </>
  );
}

export default App;
